<template>
    <div class="order-table">
        <div class="orderlist">
            <div class="orderstatus">
                <div class="circular" :style="{'background-color': color}"></div>
                <div style="font-size: 18px; margin-bottom: 10px; font-weight: 600" :style="{'color': color}">订单状态：{{name}}订单</div>
            </div>
            <el-table :data="order" style="width: 100%;text-align: center;"
                      class="table-class"
                      v-el-table-infinite-scroll="load"
                      ref="table"
                      max-height="600"
                    lazy
                      :row-class-name="tableRowClassName" :header-cell-class-name="headerCellClassName">
                <el-table-column prop="topicTitle" label="话题" width="200" align="center">
                </el-table-column>
                <el-table-column prop="title" label="咨询标题" width="200" align="center">
                </el-table-column>
<!--                <el-table-column prop="expertName" label="专家" width="140" align="center">-->
<!--                </el-table-column>-->
                <el-table-column prop="userName" label="委托人" width="140" align="center">
                </el-table-column>
                <el-table-column prop="creatTime" label="下单时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="creatTime" label="预约时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="status" label="订单状态" width="140" align="center">
                    <template slot-scope="scope">
                        <div style="font-size: 16px;" :style="{'color': color}">{{orderStatus(scope.row.status)}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row.orderNo)" type="text" size="small">
                            查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import elTableInfiniteScroll from 'el-table-infinite-scroll';
    export default {
        name: "OrderTable",
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        data() {
            return {
                color: '#F44336',
                name: '待接单',
                page: 1,
                more: 1,

                order: [],
            }
        },

        props: {
            type: {
                type: Number,
                default() {
                    return 0;
                }
            },

            expertUuid: {
                type: String,
                default() {
                    return '';
                }
            }
        },

        watch: {},

            mounted() {
                switch (this.type) {
                    case 1 :
                        this.color = '#F44336';
                        this.name = '待接单';
                        break;
                    case 3 :
                        this.color = '#FF9800';
                        this.name = '进行中';
                        break;
                    case 4 :
                        this.color = '#F44336';
                        this.name = '待确认';
                        break;
                    case 5 :
                        this.color = '#2970FF';
                        this.name = '待评价';
                        break;
                    case 6 :
                        this.color = '#8BC34A';
                        this.name = '完成';
                        break;
                }
                this.getExpertOrder();
            },

            methods: {
                // 获取专家订单
                getExpertOrder() {
                    this.newApi.getExpretOrderList({
                        page: this.page,
                        pageSize: 10,
                        status: this.type,
                        expert: this.expertUuid,
                    }).then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            this.order.push(res.data[i]);
                        }
                        if (res.data.length < 10) {
                            this.more = 0;
                        }
                        this.page++;
                    });
                },

                load() {
                    if (
                        this.page < 1 || (this.$refs.table && this.$refs.table.bodyWrapper && this.$refs.table.bodyWrapper.scrollTop == 0)
                    ) {
                        return false;
                    }
                    setTimeout(() => {
                        if (this.more === 1) {
                            this.getExpertOrder()
                        }
                    }, 500);
                },
                handleClick(orderNo) {
                    this.utils.a('/expert/order/' + orderNo);
                },

                tableRowClassName() {
                    return 'success-row';
                },

                headerCellClassName() {
                    var color = '';
                    switch (this.type) {
                        case 1 :
                            color = 'headerCell red';
                            break;
                        case 3 :
                            color = 'headerCell yellow';
                            break;
                        case 4 :
                            color = 'headerCell red';
                            break;
                        case 5 :
                            color = 'headerCell blue';
                            break;
                        case 6 :
                            color = 'headerCell green';
                            break;
                    }
                    return color
                },

                orderStatus(status) {
                    if (status == 1) {
                        return "待接单"
                    } else if (status == 2) {
                        return "待支付"
                    } else if (status == 3) {
                        return "进行中"
                    } else if (status == 4) {
                        return "待确认"
                    } else if (status == 5) {
                        return "待评价"
                    } else if (status == 6) {
                        return "已完成"
                    }
                }
            }
    }
</script>

<style scoped>
    .order-table .mainPlate .plate .orderstatus {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 16px;
        font-size: 20px;
    }

    .order-table .mainPlate .plate .orderstatus .circular {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .order-table .mainPlate .plate .el-table .headerCell {
        height: 40px;
        color: #ffffff;
        text-align: center;
        background-color: #2970FF;
    }

    .order-table .mainPlate .plate .el-table .headerFirstCell {
        border-top-left-radius: 5px;
        height: 40px;
        color: #ffffff;
        background-color: #2970FF;
        text-align: center;
    }

    .order-table .mainPlate .plate .el-table .headerLastCell {
        border-top-right-radius: 5px;
        height: 40px;
        color: #ffffff;
        background-color: #2970FF;
        text-align: center;
    }

    .order-table .mainPlate .plate .el-table .success-row {
        height: 56px;
        background: #F5F5F5;
        text-align: center;
    }

    /deep/ .el-table__body-wrapper {
        height: 100% !important;
    }

    .red {
        background-color: #F44336 !important;
    }

    .yellow {
        background-color: #FF9800 !important;
    }

    .green {
        background-color: #8BC34A !important;
    }

    .blue {
        background-color: #2970FF !important;
    }

</style>