<template>
    <div id="expert_info">
        <div style="width: 1500px;margin: 108px auto 0;min-height: calc(100vh - 108px)">
            <div style="margin-right: 10px; float: left">
                <div style="width: 1120px; background-color: #ffffff;margin-bottom: 10px;border-radius: 5px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);padding: 30px;box-sizing: border-box;">
                    <div class="introduction">
                        <img :src="expert.headIco" class="head_ico">
                        <div class="detail">
                            <div style="display: flex;justify-content: flex-start;">
                                <div class="name">{{ expert.nickName }}</div>
                                <div class="fs" style="margin-left: 10px">
                                    <img src="./imgs/local.png" style="width: 14px;height: auto;margin:0 5px;">
                                    <div class="t2"><span v-if="expert.address.cityName">{{expert.address.cityName[1]}}</span><span v-else>暂无</span><span v-if="expert.major"> | </span>{{expert.major}}</div>
                                </div>
                            </div>
                            <div class="fs" style="margin-top: 10px;">
                                <div style="margin-right: 40px;"><span class="t3"
                                                                       style="margin-right: 10px">帮助过</span><span
                                        style="font-size: 16px;color: #2970FF;font-weight: 600;">1230人</span></div>
                                <div style="margin-right: 40px;"><span class="t3"
                                                                       style="margin-right: 10px">响应率</span><span
                                        style="font-size: 16px;color: #2970FF;font-weight: 600;">82%</span></div>
                                <div style="margin-right: 40px;"><span class="t3"
                                                                       style="margin-right: 10px">完成订单数</span><span
                                        style="font-size: 16px;color: #2970FF;font-weight: 600;">268单</span></div>
                            </div>
                        </div>
                        <div class="btns">
                            <!-- 						<div class="btn" style="margin-right: 20px;">编辑话题</div> -->
                            <div class="btn hand op" style="margin-right: 20px;" @click="addTopicWin"
                                 v-if="user.uuid == expert.uuid">添加话题
                            </div>
                            <div class="btn hand op" v-if="expert.uuid !== user.uuid" style="margin-right: 20px;" @click="collect(expert.uuid)">关注专家</div>
                            <div class="btn hand op" v-if="expert.uuid !== user.uuid" @click="toMessages">私聊</div>
                        </div>
                    </div>
                    <div
                            style="background-color: #ffffff; line-height: 30px;font-size: 18px;color: #444444;margin-top: 20px;overflow: hidden;">
                        <span style="color: #999999">专家简介：</span>
                        <span>{{ expert.expertInfo.explainInfo }}</span>
                    </div>

                    <div
                            style="background-color: #ffffff; line-height: 30px;font-size: 18px;color: #444444;margin-top: 10px;overflow: hidden;">
                        <span style="color: #999999">出版专著：</span>
                        <span v-for="item in expert.expertInfo.paper.monograph"
                              :key="item.monographName">{{ item.monographName }}、</span>
                    </div>

                    <div
                            style="background-color: #ffffff; line-height: 30px;font-size: 18px;color: #444444;margin-top: 10px;overflow: hidden;">
                        <span style="color: #999999">发表论文：</span>
                        <span v-for="item in expert.expertInfo.paper.paper"
                              :key="item.paperNo">{{ item.paperName }}、</span>
                    </div>

                    <div
                            style="background-color: #ffffff; line-height: 30px;font-size: 18px;color: #444444;margin-top: 10px;overflow: hidden;">
                        <span style="color: #999999">社会头衔：</span>
                      <span v-for="(item,index) in expert.expertInfo.rank" :key="index">{{ item.name }}<span v-if="index < expert.expertInfo.rank.length - 1">、</span></span>
                    </div>
                    <!--
                            <div style="background-color: #ffffff; line-height: 30px;font-size: 18px;color: #444444;margin-top: 10px;overflow: hidden;">
                              <span style="color: #999999">参与行业标准：</span>
                              <span>球网报道，对于媒体炒作的所谓“蔡佩会”，中国外交部发言人赵立坚做出回应，并把话挑明指出，中方对佩洛西众议长可能进行的窜访行程做出的警告已经十分清楚，窜访如成行将严重侵害中美球网报道，对于媒体炒作的所谓“蔡佩会”，中国外交部发言人赵立坚做出回应并把话挑明指出，中方对佩洛西众议长可能进行的窜访行程做出的警告已经十分清楚。</span>
                            </div> -->

                </div>

                <div style="width: 1060px; background-color: #FFFFFF; display:flex;justify-content: space-between;align-items: center;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px 5px 0 0;height: 63px;padding: 0 30px;">
                    <div style="overflow: hidden">
                        <div class="hand op" style="float: left;margin-right: 60px;height: 63px;line-height: 62px;box-sizing: border-box;"
                             :class="{'active':isActive==1}" @click="changeActive(1)">专家订单
                        </div>
                        <div class="hand op" style="float: left;margin-right: 60px;height: 63px;line-height: 62px;box-sizing: border-box;"
                             :class="{'active':isActive==3}" @click="changeActive(3)">审查订单
                        </div>
                        <div class="hand op" style="float: left;height: 63px;line-height: 62px;box-sizing: border-box;"
                             :class="{'active':isActive==2}" @click="changeActive(2)">专家评价
                        </div>
                    </div>
                    <el-button v-if="user.uuid !== uuid" type="primary" plain @click="ConsultOtherTopic">咨询其他话题
                    </el-button>
                </div>

                <div v-if="isActive == 1">
                    <div class="topic" style="margin-bottom: 20px;" v-if="user.uuid === $route.params.uuid">
                        <div style="padding: 10px 20px;">
                            <OrderTable :type="1" :expertUuid="$route.params.uuid"></OrderTable>
                        </div>
                    </div>
                    <div class="topic" style="margin-bottom: 20px;" v-if="user.uuid === $route.params.uuid">
                        <div style="padding: 10px 20px;">
                            <OrderTable :type="3" :expertUuid="$route.params.uuid"></OrderTable>
                        </div>
                    </div>
                    <div class="topic" style="margin-bottom: 20px;" v-if="user.uuid === $route.params.uuid">
                        <div style="padding: 10px 20px;">
                            <OrderTable :type="4" :expertUuid="$route.params.uuid"></OrderTable>
                        </div>
                    </div>
                    <div class="topic" style="margin-bottom: 20px;" v-if="user.uuid === $route.params.uuid">
                        <div style="padding: 10px 20px;">
                            <OrderTable :type="5" :expertUuid="$route.params.uuid"></OrderTable>
                        </div>
                    </div>
                    <div class="topic" style="margin-bottom: 20px;">
                        <div style="padding: 10px 20px;">
                            <OrderTable :type="6" :expertUuid="$route.params.uuid"></OrderTable>
                        </div>
                    </div>
                </div>

                <div v-if="isActive == 2" style="height: 600px; background-color: #FFFFFF">
                    <div v-for="(item, index) in evaluateList" :key="index">
                        <div style="display: flex; justify-content: space-between; padding: 20px">
                            <div style="width: 21%">
                                <div style="overflow: hidden">
                                    <div style="float: left;width: 60px;height: 60px;" class="hand op">
                                        <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="item.userHead +'-200x200'">
                                    </div>
                                    <div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 160px;height: 60px;">
                                        <div style="height: 22px;font-weight: bold;line-height: 20px;margin-top: 10px;">{{item.userName}}</div>
                                        <div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;"><span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.userMajor && item.address.cityName"> | </span>{{item.userMajor}}</div>
                                    </div>
                                </div>
                                <div class="hand op" @click="utils.a('/expert/order/' + item.orderNo)" style="color:#2970FF; font-size: 15px; display: flex; justify-content: center; margin-top: 10px">查看咨询详情 &lt;&lt;</div>
                            </div>
                            <div style="width: 76%">
                                <div style="display: flex; margin-bottom: 6px">
                                    <el-rate v-model="item.score" color="#FF0000" :disabled="true" :show-text="true" :colors="['#E4393C', '#E4393C', '#e4393c']" :texts="['极差', '失望', '一般', '满意', '非常满意']" text-color="#E4393C"></el-rate>
                                    <span style="margin-left: 40px; font-size: 14px; margin-top: 10px">专家话题：{{item.topicTitle}}</span>
                                </div>
                                <div style="width: 800px; margin-bottom: 10px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                    {{item.content}}
                                </div>
                                <div style="display: flex; font-size: 14px">
                                    <span style="color: #999999">{{item.creationTime}}</span>
                                </div>
                            </div>
                        </div>
                        <el-divider></el-divider>
                    </div>
                </div>

                <div v-if="isActive == 3">
                    <div class="topic" style="margin-bottom: 20px;">
                        <div style="padding: 10px 20px;">
                            <el-table :data="serviceOrder" style="width: 100%;text-align: center;"
                                      :row-class-name="tableRowClassName" :header-cell-class-name="headerCellClassName">
                                <el-table-column prop="serviceTitle" label="咨询标题" width="180" align="center">
                                </el-table-column>
                                <el-table-column style="text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;" prop="serviceContent" label="咨询内容" width="220" align="center">
                                    <template slot-scope="scope">
                                        <el-popover
                                            placement="bottom"
                                            title="咨询内容"
                                            width="560"
                                            trigger="hover"
                                            :content="scope.row.serviceContent">
                                            <div slot="reference" style="text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">{{scope.row.serviceContent}}</div>
                                        </el-popover>

                                    </template>
                                </el-table-column>
                                <el-table-column prop="nickName" label="委托人" width="140" align="center">
                                </el-table-column>
                                <el-table-column prop="createTime" label="下单时间" width="110" align="center">
                                </el-table-column>
                                <el-table-column prop="updateTime" label="更新时间" width="110" align="center">
                                </el-table-column>
                                <el-table-column prop="status" label="订单状态" width="120" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.isSendOrders == 0 && scope.row.serviceStatus < 9">待接单</div>
                                        <div v-if="scope.row.isSendOrders == 1 && scope.row.serviceStatus < 9">已接单</div>
                                        <div v-if="scope.row.isSendOrders == 2 && scope.row.serviceStatus < 9">拒绝接单</div>
                                        <div v-if="scope.row.serviceStatus >= 9">订单失效</div>

                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="190" align="center">
                                    <template slot-scope="scope">
                                        <el-button @click="b('/shop/serviceInfo/' + scope.row.serviceOrderNo)" type="text" size="small">
                                            查看详情
                                        </el-button>
                                        <el-button v-if="scope.row.isSendOrders === 0 && scope.row.serviceStatus < 9" @click="optionServiceOrder(1, scope.row.orderNo)" type="text" style="color: #8BC34A; font-weight: 600" size="small">
                                            同意接单
                                        </el-button>
                                        <el-button v-if="scope.row.isSendOrders === 0 && scope.row.serviceStatus < 9" @click="optionServiceOrder(2, scope.row.orderNo)" type="text" style="color: #F44336; font-weight: 600" size="small">
                                            拒接接单
                                        </el-button>
                                        <div v-if="scope.row.serviceStatus === 9">已完成</div>
                                        <div v-if="scope.row.serviceStatus === 10">已撤单</div>
                                        <div v-if="scope.row.serviceStatus === 11">已退款</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>

            <div style="width:370px;float: right;">
                <div class="expert-content">
                    <div class="triangle"></div>
                    <div class="head">
                        <div class="title">专家话题</div>
                        <div class="more"></div>
                    </div>

                    <div class="main">
                        <div v-for="(item, index) in expert.topic" :key="item.id">
                            <div style="font-size: 18px; font-weight: 600; line-height: 34px">
                                {{item.title}}
                            </div>
                            <div v-html="item.detail" style="color: #999999; line-height: 26px; margin-bottom: 12px">
                            </div>
                            <div style="display: flex; justify-content: space-between">
                                <div style="font-size: 18px; font-weight: 600">
                                    <span>咨询费用：</span>
                                    <span style="color: #FF9900">{{item.money}}元</span>
                                    <div style="font-size: 16px;color: #999999; font-weight: 400; margin-top: 10px">
                                        <span>咨询费用说明：<br>{{ item.money }}元/小时，过时加费</span>
                                    </div>
                                </div>
                                <div class="hand op" v-if="item.uuid !== user.uuid" @click="chooseTopic(index)"
                                     style="width: 100px; height: 24px; background-color: #FF9800; color: #FFFFFF; text-align: center; padding: 4px 6px; border-radius: 5px">
                                    咨询话题
                                </div>

                                <div class="hand op" v-else @click="showEdit = true"
                                     style="width: 100px; height: 22px; background-color: #FF9800; color: #FFFFFF; text-align: center; padding: 5px 6px; border-radius: 5px">
                                    编辑话题
                                </div>
                            </div>
                            <hr style="color: #999999; margin: 20px 0">
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <el-drawer :visible.sync="showConsult" direction="ttb" custom-class="demo-drawer" ref="drawer" size="100%"
                   :with-header="false">
            <div class="cform">
                <div class="top">
                    填写咨询订单
                    <img src="./imgs/cancel.png" style="position: absolute;right: 30px;top: 14px;cursor: pointer;"
                         @click="consultCancel">
                </div>
                <div class="consultHead" v-if="form.expertTopic != 0">

                    <img class="ico" :src="expert.headIco">
                    <div class="exportDetail">
                        <div style="padding: 10px 0">
                            <span style="color: #999999;">咨询专家：</span><span
                                style="color: #444444;">{{ expert.expertInfo.realName }}</span>
                        </div>
                        <div style="padding: 10px 0">
                            <span style="color: #999999;">专家评价：</span><span style="color: #444444;">帮助过<span
                                style="color: #2970FF">1230人</span>，响应率<span
                                style="color: #2970FF">82%</span>，完成订单数<span style="color: #2970FF">268单</span></span>
                        </div>
                        <div style="padding: 10px 0">
                            <span style="color: #999999;">咨询话题：</span><span
                                style="color: #FF9800;">{{ topic.title }}</span>
                        </div>
                        <div style="padding: 10px 0">
                            <span style="color: #999999;">咨询费用：</span><span style="color: #444444;">{{ topic.money }}元/小时</span>
                        </div>
                        <div style="padding: 10px 0">
                            <span style="color: #999999;">咨询分类：</span><span
                                style="color: #444444;">{{ topic.typeName[0] }}-{{ topic.typeName[1] }}-{{ topic.typeName[2] }}</span>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <el-form ref="form" :model="form" :rules="rules" label-position="top">
                        <el-form-item label="咨询话题" v-if="form.expertTopic == 0" prop="topicTitle">
                            <el-input v-model="form.topicTitle" placeholder="请输入咨询话题"></el-input>
                        </el-form-item>
                        <el-form-item label="咨询方式" prop="type">
                            <el-radio-group v-model="form.type">
                                <el-radio :label="1">视频咨询</el-radio>
                                <el-radio :label="2">在线函复</el-radio>
                                <el-radio :label="3">现场会议</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-row :gutter="40">
                            <el-col :span="12">
                                <el-form-item label="咨询预约日期" prop="consultTime">
                                    <el-date-picker v-model="form.consultTime" type="datetime" placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                                                    :picker-options="timePickerOptions"
                                                    placement="bottom-start">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" v-if="form.type !== 2">
                                <el-form-item label="咨询时长" style="position: relative;" prop="consultDuration">
                                    <el-input-number v-model="form.consultDuration" @change="handleChange"
                                                     label="描述文字"></el-input-number>
                                    <div style="position: absolute;right: 0;top: 0;text-align: center;">
                                        <p style="font-size: 16px;color: #444444;line-height: 20px;">小时</p>
                                        <p style="font-size: 14px;color: #999999;line-height: 20px;">（超时加收费）</p>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="40">
                            <el-col :span="12">
                                <el-form-item label="咨询费用" style="position: relative;" prop="consultCost">
                                    <el-input-number v-model="form.consultCost" label="描述文字"></el-input-number>
                                    <div style="position: absolute;right: 0;top: 0;text-align: center;">
                                        <p style="font-size: 16px;color: #444444;line-height: 20px;">元</p>
                                        <p style="font-size: 14px;color: #999999;line-height: 20px;">（超时加收费）</p>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">

                            </el-col>
                        </el-row>
                        <el-form-item label="咨询标题" prop="title">
                            <el-input v-model="form.title" placeholder="请输入咨询话题"></el-input>
                        </el-form-item>
                        <el-form-item label="咨询内容" prop="content">
                            <el-input type="textarea" v-model="form.content" :rows="6" placeholder="请输入内容"></el-input>
                        </el-form-item>

                        <el-form-item label="上传附件">
                            <el-upload class="upload-demo" drag action="#" multiple :http-request="upload"
                                       :file-list="fileList">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="附件说明及备注">
                            <el-input type="textarea" v-model="form.remark" :rows="6" placeholder="请输入内容"></el-input>
                        </el-form-item>

                        <el-form-item label="是否公开">
                          <el-radio v-model="form.isOpen" :label="0">不公开</el-radio>
                          <el-radio v-model="form.isOpen" :label="1">公开</el-radio>
                        </el-form-item>
                    </el-form>
                    <div
                            style="cursor: pointer;background: #2970FF;color: #ffffff;margin: 30px auto 40px ;padding: 14px 40px;box-sizing: border-box; width: 150px; text-align: center;"
                            @click="submitForm">生成订单
                    </div>
                </div>

            </div>
        </el-drawer>


        <el-drawer :visible.sync="showEdit" direction="ttb" custom-class="demo-drawer" ref="drawer" size="100%"
                   :with-header="false">
            <div class="cform">
                <div class="top">
                    编辑专家话题
                    <img src="./imgs/cancel.png" style="position: absolute;right: 30px;top: 14px;cursor: pointer;"
                         @click="showEdit = false">
                </div>
                <div class="main">
                    <div
                            style="display: flex;justify-content: space-between;align-items: center;height: 30px;line-height: 30px;margin-bottom: 15px;">
                        <div style="font-size: 20px;">咨询话题</div>
                        <el-link type="primary" @click="showAddBox">添加</el-link>
                    </div>
                    <ExpertTopic v-if="addTopic" @addExpertTopics="addExpertTopics"></ExpertTopic>
                    <div class="expertTopic" v-for=" (item,index) in topics" :key="item.id">
                        <ExpertTopic v-if="item.isEdit == 1" :data="item" :isEdit="1" :index="index"
                                     @changeTopic="changeTopic(arguments)"></ExpertTopic>
                        <div v-if="item.isEdit == 0">
                            <div
                                    style="display: flex;justify-content: space-between;align-items: center;height: 30px;line-height: 30px;">
                                <div>{{ item.title }}</div>
                                <div style="display: flex;justify-content:flex-end;align-items: center;">
                                    <!-- 									<div style="margin-right: 20px;">上架</div>
                                                      <div style="margin-right: 20px;">下架</div> -->
                                    <div
                                            style="width: 30px;height: 30px;border-radius: 50%;background-color: #E4E6EB;font-size: 13px;line-height: 30px;text-align: center;margin-right: 20px;"
                                            @click="editTopic(index)">
                                        <i class="el-icon-edit"></i>
                                    </div>
                                    <div
                                            style="width: 30px;height: 30px;border-radius: 50%;background-color: #E4E6EB;font-size: 13px;line-height: 30px;text-align: center;"
                                            @click="delExpertTopic(item.id,index)">
                                        <i class="el-icon-delete"></i>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                话题简介：{{ item.detail }}
                            </div>
                            <div style="margin-top: 10px;">
                                咨询费用：{{ item.money }}元
                            </div>
                            <div style="margin-top: 10px;">
                                咨询分类：{{ item.typeName[2] }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </el-drawer>

        <!--私信-->
        <div class="show-message">
            <el-dialog :visible.sync="showMessage" width="940px">
                <div class="dialog-title">
                    <div style="margin-left: 40%">
                        <span>专家私信</span>
                    </div>
                </div>
                <el-divider></el-divider>
                <div style="padding: 20px">
                    <ToMessage :uuid="uuid" :type="1"></ToMessage>
                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
    import ExpertTopic from "./components/ExpertTopic";
    import OrderTable from "./components/OrderTable";
    import ToMessage from "./components/ToMessage";

    export default {
        name: "",
        components: {
            ToMessage,
            OrderTable,
            ExpertTopic
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },
        data() {
            var durationRange = (rule, value, callback) => {
                if (value < 1) {
                    callback(new Error('最少咨询1小时'));
                } else if (value > 8) {
                    callback(new Error('最多咨询8小时'));
                } else {
                    callback()
                }
            };

            return {
                showMessage: false,

                input: "",
                showConsult: false,
                showEdit: false,
                addTopic: false,
                isActive: 1,

                form: {
                    expert: "",
                    expertTopic: "",
                    title: '',
                    content: '',
                    type: 1,
                    topicTitle: '',
                    consultCost: '',
                    consultTime: "",
                    consultDuration: 1,
                    remark: '',
                    isOpen:0
                },
                rules: {
                    topicTitle: [
                        {required: true, message: '请输入咨询话题', trigger: 'blur'},
                        {min: 3, max: 25, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    consultTime: [
                        {required: true, message: '请选择咨询时间', trigger: 'blur'}
                    ],
                    consultDuration: [
                        {required: true, message: '请输入时长', trigger: 'change'},
                        {validator: durationRange, trigger: 'blur'}
                    ],
                    type: [
                        {required: true, message: '请选择咨询方式', trigger: 'blur'}
                    ],
                    title: [
                        {required: true, message: '请填写咨询标题', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请填写咨询内容', trigger: 'blur'}
                    ]
                },
                timer: null,
                uuid: "",

                newOrder: [],

                expert: {
                    expertInfo: {
                        realName: "",
                          introduction: "",
                          paper: {
                              paper: [],
                              monograph: [],
                          }
                    },
                },
                topics: [],
                topic: {
                    typeName: ["", "", ""],
                    money: 0,
                },
                kwTypeArr: [],
                fileList: [],
                enclosure: [],
                ossUrl: "https://oss.baigongbao.com/",
                //咨询时间的过滤规则(不能小于今天)
                timePickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;//如果没有后面的-8.64e7就是不可以选择今天的
                    }
                },

                order: {
                    num: 5
                },

                // 评价列表
                evaluateList: [],

                // 服务审查订单
                serviceOrder: []
            }
        },
        props: {},
        created: function () {

        },
        mounted: function () {
            this.uuid = this.$route.params.uuid;
            this.getExpertInfo();
        },
        methods: {
          addTopicWin() {
            this.showEdit = true;
            this.showAdd()
          },

            // 获取审查订单
            getServiceOrderList() {
                this.newApi.examineOrderTakingList({}).then(res => {
                    this.serviceOrder = res.data;
                });
            },

            // 审查订单操作
            optionServiceOrder(status, orderNo) {
                this.newApi.examineOrderTaking({type: status, orderNo: orderNo}).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.getServiceOrderList();
                    }
                })
            },

            // 获取专家评价getOrderEvaluate
            getOrderEvaluate() {
                this.newApi.getExpertEvaluateList({type: 1, expert: this.expert.uuid, topicId: 0}).then(res => {
                    if (res.isSuccess == 1) {
                        this.evaluateList = res.data;
                    }
                })
            },


            // 私信
            toMessages() {
                this.showMessage = true
            },

            consultCancel() {
                this.showConsult = false;
                this.fileList = [];
                this.form = {
                    expert: "",
                    expertTopic: "",
                    title: '',
                    content: '',
                    type: 1,
                    topicTitle: '',
                    consultCost: '',
                    consultTime: "",
                    consultDuration: 1,
                    remark: ''
                };
            },
            collect(id) {
                var params = {};
                params.uuid = id;
                this.newApi.collectionExpert(params).then(res => {
                    this.utils.sus(res.data)
                })
            },
            ConsultOtherTopic() {
                this.showConsult = true;
                this.form.expert = this.uuid;
                this.form.expertTopic = 0;
                this.form.topicTitle = "";
            },
            getExpertInfo() {
                var params = {};
                params.uuid = this.uuid
                this.newApi.getExpertInfo(params).then(res => {
                    this.topics = [];
                    this.expert = res.data;
                    for (var i = 0; i < res.data.topic.length; i++) {
                        res.data.topic[i].isEdit = 0;
                        this.topics.push(res.data.topic[i])
                    }
                    this.getOrderEvaluate()

                })
            },
            tableRowClassName() {
                return 'success-row';
            },
            headerCellClassName({columnIndex}) {
                if (columnIndex === 0) {
                    return 'headerFirstCell';
                } else if (columnIndex === 7) {
                    return 'headerLastCell';
                }
                return 'headerCell'
            },
            handleClick(orderNo) {
                this.utils.a('/expert/order/' + orderNo);
            },

            handleChange() {
                if (this.form.type == 1 && this.form.expertTopic != 0) {
                    this.form.consultCost = this.form.consultDuration * this.topic.money;
                }
            },

            showAdd() {
              this.addTopic = true;
            },

            showAddBox() {
              this.addTopic = !this.addTopic
            },
            editTopic(index) {
                var to = this.topics[index];
                to.isEdit = 1;
                this.$set(this.topics, index, to);
            },
            changeTopic(params) {
                this.$set(this.topics, params[1], params[0]);
            },
            delExpertTopic(id, index) {
                var params = {};
                params.id = id
                this.newApi.delExpertTopic(params).then(res => {
                    this.utils.sus(res.data)
                    this.topics.splice(index, 1)
                })
            },
            addExpertTopics(data) {
                this.topics.push(data);
                console.log(this.topics)
                this.addTopic = false
            },
            chooseTopic(index) {
                this.topic = {...this.expert.topic[index]} ;
                this.form = {
                  expert: this.topic.uuid,
                  expertTopic: this.topic.id,
                  topicTitle: this.topic.title,
                  content: '',
                  type: 1,
                  title: '',
                  consultCost: this.topic.money,
                  consultTime: "",
                  consultDuration: 1,
                  remark: '',
                  isOpen:0
                };
                this.showConsult = true;
            },
            upload(params) {
                this.fileList.push(params.file)
            },
            submitForm() {
                var that = this;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        that.makeConsult()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            makeConsult() {
                var that = this;
                var params = that.form;
                that.enclosure = [];
                let filesPromise = [];
                for (let i = 0; i < that.fileList.length; i++) {
                    filesPromise.push(new Promise(function (resolve) {
                        that.utils.upload(that.fileList[i], function (url) {
	                        if (!url){
		                        return false;
	                        }
                            let obj = {};
                            obj.img_url = that.ossUrl + 'img/file.png';
                            obj.name = that.fileList[i].name.substring(0, that.fileList[i].name.lastIndexOf("."));
                            obj.size = that.fileList[i].size;
                            obj.type = that.fileList[i].type;
                            obj.url = url;
                            that.enclosure.push(obj);
                            resolve(obj);
                        })
                    }))
                }
                Promise.all(filesPromise).then(_ => {
                    console.log(_);
                    params.enclosure = that.enclosure;
                    params.enclosure = JSON.stringify(params.enclosure);
                    that.newApi.makeConsult(params).then(res => {
                        that.utils.sus(res.data)
                        that.showConsult = false;
                        that.fileList = [];
                    })
                })
            },
            changeActive(active) {
                var that = this;
                that.isActive = active;
                if (active == 3) {
                    this.getServiceOrderList();
                }
              if (active == 2) {
                this.getOrderEvaluate();
              }
            }
        }
    }

</script>

<style>
    #expert_info {
        background-color: #f0f2f5;
        overflow: hidden;
    }

    #expert_info .introduction {
        display: flex;
        justify-content: flex-start;
        position: relative;
    }

    #expert_info .introduction .btns {
        position: absolute;
        top: 20px;
        right: 0;
        display: flex;
        justify-content: flex-start;
    }

    #expert_info .introduction .btns .btn {
        padding: 8px 20px;
        font-size: 18px;
        color: #FF9800;
        border-radius: 5px 5px 5px 5px;
        border: 1px solid #FF9800;
        font-weight: 600;
    }

    #expert_info .introduction .head_ico {
        width: 80px;
        height: 80px;
        flex: 0 0 auto;
    }

    #expert_info .introduction .detail {
        margin-left: 40px;
    }

    #expert_info .introduction .detail .name {
        font-size: 22px;
        color: #444444;
        margin: 10px 0;
    }

    #expert_info .topic {
        width: 1120px;
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        /*		margin-bottom: 10px;*/
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
    }

    #expert_info .el-table .success-row {
        height: 56px;
        background: #F5F5F5;
        text-align: center;
    }

    #expert_info .el-table .cell {
        text-align: center;
    }

    #expert_info .el-table .headerCell {
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert_info .el-table .headerFirstCell {
        border-top-left-radius: 5px;
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert_info .el-table .headerLastCell {
        border-top-right-radius: 5px;
        height: 40px;
        background-color: #2970FF !important;
        color: #ffffff;
        text-align: center;
    }

    #expert_info .el-table__body, .el-table__footer {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0px 10px;
    }

    .fs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .sb {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .t2 {
        font-size: 16px;
        color: #666666;
    }

    .t3 {
        font-size: 16px;
        color: #999999;
    }

    .el-drawer {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .cform {
        width: 1000px;
        overflow: hidden;
        margin: 150px auto;
        background-color: #ffffff;
    }

    .cform .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 65px;
        box-sizing: border-box;
        line-height: 64px;
    }

    .cform .consultHead {
        padding: 30px 0 40px 70px;
        box-sizing: border-box;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: flex-start;
    }

    .cform .consultHead .ico {
        height: 220px;
        width: 220px;
        margin-right: 40px;

    }

    .cform .consultHead .exportDetail {
        font-size: 18px;
    }

    .cform .main {
        padding: 0 50px;
        margin-top: 15px;
    }

    .cform .main .expertTopic {
        padding-bottom: 20px;
        border-bottom: 1px dashed #CECFD0;
        margin-top: 20px;
        color: #444444;
        font-size: 16px;
    }

    .expert-content {
        width: 100%;
        border-radius: 5px;
        height: auto;
        background-color: #FFFFFF;
        position: relative;
        margin-bottom: 10px;
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.1600);
        padding: 20px 30px;
        box-sizing: border-box;
    }

    .triangle {
        position: absolute;
        border-radius: 5px 0 0 0;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border: 14px solid #fff;
        border-top-color: #FF9900;
        border-left-color: #FF9900;
        border-bottom-color: transparent;
        border-right-color: transparent;

    }

    .expert-content .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .expert-content .head .title {
        height: 26px;
        font-size: 20px;
        font-weight: 600;
        color: #FF9900;
    }

    .expert-content .head .more {
        height: 19px;
        font-size: 16px;
        font-weight: 600;
        color: #FFA500;
    }

    .expert-content .main {
        margin-top: 10px;
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    .show-message .el-dialog__header {
        padding: 0;
    }

    .show-message .el-dialog__body {
        padding: 0;
    }

    /*下划线*/
    .show-message .el-divider--horizontal {
        margin: 0;
    }

    .show-message .el-input__inner {
        height: 38px;
        line-height: 38px;
        background-color: #F0F2F5;
        border-radius: 30px;
        border: none;
    }

    .active {
        border-bottom: 1px solid #2970FF;
        color: #2970FF;
    }

    .checkout-tag {
        padding: 4px 12px;
        border-radius: 10px;
        font-size: 18px;
        margin-right: 20px;
        color: #2970FF;
        border: 1px #2970FF solid;
    }

    .select-tag {
        padding: 4px 12px;
        border-radius: 10px;
        font-size: 18px;
        margin-right: 20px;
        background-color: #2970FF;
        color: #FFFFFF;
    }

    .el-input-number {
        position: relative;
        display: inline-block;
        width: 280px;
        line-height: 40px;
    }

    .el-radio__label {
        font-size: 16px;
        color: #555555;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .el-select {
        width: 100%;
    }
</style>
