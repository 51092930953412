<template>
	<div style="width: 100%;">
		<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
			<div style="width: 100px;">话题标题：</div>
			<div style="flex: 1">
				<el-input v-model="data.title" placeholder="请输入内容"></el-input>
			</div>
		</div>
		<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
			<div style="width: 100px;">话题简介</div>
			<div style="flex: 1">
				<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入内容" v-model="data.detail"></el-input>
			</div>
		</div>
		<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
			<div style="width: 100px;">咨询费同：</div>
			<div style="flex: 1">
				<el-input-number v-model="data.money" :precision="2" :step="1" ></el-input-number>
			</div>
		</div>
		<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
			<div style="width: 100px;">专业分类</div>
			<div style="flex: 1">
				<el-cascader v-model="data.typeArr" :options="typeList" @change="handleChange"></el-cascader>
			</div>
		</div>
		<div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 15px;">
			<div style="width: 100px;">是否上架：</div>
			<div style="flex: 1">
				<el-switch v-model="data.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1"></el-switch>
			</div>
		</div>
		<el-button type="primary" v-if="isEdit == 1" style="width: 200px;margin: 10px 0;" @click="editExpertTopic">保存</el-button>
		<el-button type="primary" v-else style="width: 200px;margin: 10px 0;" @click="addExpertTopic">提交</el-button>
	</div>
</template>
<script>
	export default {
		name:"NoData",
		components: {

		},
		data () {
			return {
				typeList:[],
			}
		},
		props:{
			data:{
                type:Object,
                default() {
                    return {
						detail:"",
						title:"",
						money:"",
						typeArr:[],
						status:""

                    }
                }
            },
			isEdit:{
				type:Number,
				default(){
					return 0;
				}
			},
			index:{
				type:Number,
				default(){
					return 0;
				}
			}
		},
		created:function(){

		},
		mounted: function () {
			this.getTypeList();
		},
		methods:{
			handleChange(value){
				var params = {};
				params.topicId = value[2];
				this.newApi.getTypeName(params).then(res=>{
					this.data.typeName = res.data;
				})
			},
			getTypeList(){
				this.newApi.getTypeList().then(res=>{
					this.typeList = res.data;
				})
			},
			editExpertTopic(){
				var params = {}
        if (!this.data.title) {
          this.utils.msg('请填写话题标题')
          return
        }
        if (!this.data.detail) {
          this.utils.msg('请填写话题简介')
          return
        }
        if (this.data.typeArr.length === 0) {
          this.utils.msg('请选择专业分类')
          return
        }
				params.id = this.data.id;
				params.title = this.data.title;
				params.detail = this.data.detail;
				params.money = this.data.money;
				params.topicId = this.data.typeArr[2];
				params.uuid = this.data.uuid;
				params.status = this.data.status;
				this.newApi.editExpertTopic(params).then(res=>{
					this.utils.sus(res.data);
					this.data.isEdit = 0;
					this.$emit('changeTopic',this.data,this.index)
				})
			},
			addExpertTopic(){
        if (!this.data.title) {
          this.utils.msg('请填写话题标题')
          return
        }
        if (!this.data.detail) {
          this.utils.msg('请填写话题简介')
          return
        }
        if (this.data.typeArr.length === 0) {
          this.utils.msg('请选择专业分类')
          return
        }
				var topics = [];
				var to = {};
				to.title =  this.data.title;
				to.detail = this.data.detail;
				to.money = this.data.money;
				to.status = this.data.status;
				to.topicId = this.data.typeArr[2];
				topics.push(to);
				var params = {};
				params.topics =  JSON.stringify(topics);
				this.newApi.addExpertTopic(params).then(res=>{
					this.utils.sus(res.data)
					this.data.isEdit = 0;
					var param = {};
					param.topicId = this.data.typeArr[2];
					this.newApi.getTypeName(param).then(res=>{
						this.data.typeName = res.data;
						this.$emit('addExpertTopics',this.data)
					})

				})
			}
		}
	}
</script>
<style>

</style>
